import React from 'react'
import {
  AiOutlineMail,
  AiOutlineShop,
  AiOutlineNotification,
  AiOutlineBuild,
  AiOutlineMessage,
  AiOutlineSketch,
} from 'react-icons/ai'
import { DiPhotoshop } from 'react-icons/di'
import {
  FiZap,
  FiLock,
  FiCheckSquare,
  FiHardDrive,
  FiDatabase,
  FiMail,
  FiWifiOff,
  FiMessageSquare,
  FiUsers,
  FiTarget,
  FiUserCheck,
  FiArrowUpCircle,
  FiSearch,
  FiGlobe,
} from 'react-icons/fi'
import {
  FaCode,
  FaHtml5,
  FaPhp,
  FaReact,
  FaAngular,
  FaVuejs,
  FaSass,
  FaFlag,
  FaHome,
  FaRegEye,
  FaNodeJs,
  FaLifeRing,
  FaWpforms,
  FaMemory,
  FaRegStar,
  FaUserCircle,
  FaUsers,
  FaInfoCircle,
  FaPaintBrush,
  FaPencilAlt,
  FaRegLightbulb,
  FaRegMoneyBillAlt,
  FaAppStore,
  FaMobileAlt,
  FaWordpressSimple,
} from 'react-icons/fa'
import { GiElectric, GiStickingPlaster, GiAtom } from 'react-icons/gi'
import { GoCreditCard, GoDeviceCamera } from 'react-icons/go'
import {
  IoMdAnalytics,
  IoIosRemoveCircleOutline,
  IoLogoJavascript,
} from 'react-icons/io'
import {
  MdShoppingCart,
  MdDns,
  MdInput,
  MdSecurity,
  MdAutorenew,
  MdBrandingWatermark,
  MdImportantDevices,
  MdLocationOn,
  MdHearing,
  MdChat,
  MdSearch,
} from 'react-icons/md'
import { TiPencil } from 'react-icons/ti'
import GatsbyLogo from '../images/gatsby-logo.svg'

export const DrawIcon = ({ name }) => {
  if (name === 'GatsbyLogo') {
    return <img className="gatsby-logo" src={GatsbyLogo} />
  }
  if (name === 'JsFrameworks') {
    return (
      <div>
        <FaReact style={{ color: '#00d8ff' }} />
        <FaAngular style={{ color: '#b52e31' }} />
        <FaVuejs style={{ color: '#42b883' }} />
      </div>
    )
  }
  if (name === 'DesignSoftware') {
    return (
      <div>
        <AiOutlineSketch style={{ color: '#fdad00' }} />
        <DiPhotoshop style={{ color: '##51e25' }} />
      </div>
    )
  }
  if (name === 'AiOutlineMail') {
    return <AiOutlineMail />
  }
  if (name === 'AiOutlineBuild') {
    return <AiOutlineBuild />
  }
  if (name === 'AiOutlineShop') {
    return <AiOutlineShop />
  }
  if (name === 'AiOutlineMessage') {
    return <AiOutlineMessage />
  }
  if (name === 'AiOutlineNotification') {
    return <AiOutlineNotification />
  }
  if (name === 'FaAppStore') {
    return <FaAppStore />
  }
  if (name === 'FaCode') {
    return <FaCode />
  }
  if (name === 'FaFlag') {
    return <FaFlag />
  }
  if (name === 'FaHome') {
    return <FaHome />
  }
  if (name === 'FaHtml5') {
    return <FaHtml5 />
  }
  if (name === 'FaLifeRing') {
    return <FaLifeRing />
  }
  if (name === 'FaNodeJs') {
    return <FaNodeJs />
  }
  if (name === 'FaPhp') {
    return <FaPhp />
  }
  if (name === 'FaSass') {
    return <FaSass />
  }
  if (name === 'FaWpforms') {
    return <FaWpforms />
  }
  if (name === 'FaMobileAlt') {
    return <FaMobileAlt />
  }
  if (name === 'FaInfoCircle') {
    return <FaInfoCircle />
  }
  if (name === 'FaRegStar') {
    return <FaRegStar />
  }
  if (name === 'FaPaintBrush') {
    return <FaPaintBrush />
  }
  if (name === 'FaPencilAlt') {
    return <FaPencilAlt />
  }
  if (name === 'FaRegEye') {
    return <FaRegEye />
  }
  if (name === 'FaRegLightbulb') {
    return <FaRegLightbulb />
  }
  if (name === 'FaRegMoneyBillAlt') {
    return <FaRegMoneyBillAlt />
  }
  if (name === 'FaUserCircle') {
    return <FaUserCircle />
  }
  if (name === 'FaUsers') {
    return <FaUsers />
  }
  if (name === 'FaWordpressSimple') {
    return <FaWordpressSimple />
  }
  if (name === 'FiArrowUpCircle') {
    return <FiArrowUpCircle />
  }
  if (name === 'FiUsers') {
    return <FiUsers />
  }
  if (name === 'FiZap') {
    return <FiZap />
  }

  if (name === 'FiGlobe') {
    return <FiGlobe />
  }
  if (name === 'FiLock') {
    return <FiLock />
  }
  if (name === 'FiMessageSquare') {
    return <FiMessageSquare />
  }
  if (name === 'FiCheckSquare') {
    return <FiCheckSquare />
  }
  if (name === 'FiHardDrive') {
    return <FiHardDrive />
  }
  if (name === 'FiSearch') {
    return <FiSearch />
  }
  if (name === 'FiUserCheck') {
    return <FiUserCheck />
  }
  if (name === 'FiTarget') {
    return <FiTarget />
  }
  if (name === 'FiWifiOff') {
    return <FiWifiOff />
  }
  if (name === 'FaMemory') {
    return <FaMemory />
  }
  if (name === 'FiDatabase') {
    return <FiHardDrive />
  }
  if (name === 'FiMail') {
    return <FiMail />
  }
  if (name === 'GiAtom') {
    return <GiAtom />
  }
  if (name === 'GiElectric') {
    return <GiElectric />
  }
  if (name === 'GiStickingPlaster') {
    return <GiStickingPlaster />
  }
  if (name === 'GoCreditCard') {
    return <GoCreditCard />
  }
  if (name === 'GoDeviceCamera') {
    return <GoDeviceCamera />
  }
  if (name === 'IoIosRemoveCircleOutline') {
    return <IoIosRemoveCircleOutline />
  }
  if (name === 'IoMdAnalytics') {
    return <IoMdAnalytics />
  }
  if (name === 'IoLogoJavascript') {
    return <IoLogoJavascript />
  }
  if (name === 'MdAutorenew') {
    return <MdAutorenew />
  }
  if (name === 'MdChat') {
    return <MdChat />
  }
  if (name === 'MdHearing') {
    return <MdHearing />
  }
  if (name === 'MdLocationOn') {
    return <MdLocationOn />
  }
  if (name === 'MdSearch') {
    return <MdSearch />
  }
  if (name === 'MdShoppingCart') {
    return <MdShoppingCart />
  }
  if (name === 'MdBrandingWatermark') {
    return <MdBrandingWatermark />
  }
  if (name === 'MdDns') {
    return <MdDns />
  }
  if (name === 'MdImportantDevices') {
    return <MdImportantDevices />
  }
  if (name === 'MdInput') {
    return <MdInput />
  }
  if (name === 'MdSecurity') {
    return <MdSecurity />
  }
  if (name === 'TiPencil') {
    return <TiPencil />
  }

  return null
}
