import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Hero from '../components/Hero/Hero'
import LandingPageColumns from '../components/LandingPageColumns/LandingPageColumns'
import LandingPageContentSection from '../components/LandingPageContentSection/LandingPageContentSection'
import LandingPageFlipBoxes from '../components/LandingPageFlipBoxes/LandingPageFlipBoxes'
import LandingPageIcons from '../components/LandingPageIcons/LandingPageIcons'
import SEO from '../components/SEO'

const PageTop = ({ page }) => {
  if (page.featured_media) {
    return (
      <Hero
        bgImage={page.featured_media.localFile.childImageSharp.fluid}
        bgOverlay="true"
        noMargin="true"
        type="fluid"
        scrollDown="true"
        title={page.title}
        imageAlt={`${page.acf.main_title} background`}
        subtitleTop={page.acf.subtitle_top}
        subtitleBottom={page.acf.subtitle_bottom}
      />
    )
  }

  return (
    <section className="section no-padding-bottom">
      <div className="container">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="has-text-centered">
              <h1 className="subtitle is-size-2 is-size-3-mobile">
                {page.title}
              </h1>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export const LandingPageTemplate = ({ page }) => (
  <div className="landing-page-template">
    <PageTop page={page} />

    {page.acf.content_sections.map((section, index) => {
      if (section.section_type === 'columns') {
        return <LandingPageColumns key={`section-${index}`} section={section} />
      }
      if (
        section.section_type === 'content-left' ||
        section.section_type === 'content-right'
      ) {
        return (
          <LandingPageContentSection
            key={`section-${index}`}
            section={section}
          />
        )
      }
      if (section.section_type === 'flip-boxes') {
        return (
          <LandingPageFlipBoxes key={`section-${index}`} section={section} />
        )
      }
      if (section.section_type === 'icons') {
        return <LandingPageIcons key={`section-${index}`} section={section} />
      }
    })}
  </div>
)

const LandingPage = ({ data }) => {
  const { wordpressPage: page } = data

  return (
    <Layout color={page.acf.header_type} slug={page.slug}>
      <SEO title={page.title} />
      <LandingPageTemplate page={page} />
    </Layout>
  )
}

export default LandingPage

export const landingPageQuery = graphql`
  query LandingPageById($id: String!) {
    wordpressPage(id: { eq: $id }) {
      title
      content
      slug
      featured_media {
        localFile {
          childImageSharp {
            fluid(maxWidth: 1920, maxHeight: 1035) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      acf {
        header_type
        subtitle_top
        subtitle_bottom
        content_sections {
          section_type
          title
          subtitle
          class_name
          columns_content {
            title
            content
            icon_name
          }
          icons_content {
            title
            content
            icon_name
            label
          }
          flip_boxes_content {
            title
            subtitle
            intro
            content
            icon_name
            icon_colour
          }
          content {
            content
            image {
              localFile {
                childImageSharp {
                  fixed(width: 450, height: 450) {
                    ...GatsbyImageSharpFixed_withWebp
                  }
                }
              }
            }
            buttons {
              link {
                title
                url
                target
              }
            }
          }
        }
      }
    }
  }
`
