import React from 'react'
import { v4 } from 'uuid'
import { DrawIcon } from '../../utils/icon'

import '../LandingPageContentSection/LandingPageContentSection.scss'

class LandingPageColumns extends React.Component {
  render() {
    const { section } = this.props

    return (
      <div className="landing-page-columns section is-medium">
        <h3 className="section-header">{section.title}</h3>
        <p
          className="title-lead orange-underline centered"
          dangerouslySetInnerHTML={{
            __html: section.subtitle,
          }}
        />
        <div className="columns">
          {section.columns_content.map(column => (
            <div className="column" key={v4()}>
              <DrawIcon name={column.icon_name} />
              <h5 className="title">{column.title}</h5>
              <div
                className="content"
                dangerouslySetInnerHTML={{
                  __html: column.content,
                }}
              />
            </div>
          ))}
        </div>
      </div>
    )
  }
}

export default LandingPageColumns
