import React from 'react'
import { v4 } from 'uuid'
import { DrawIcon } from '../../utils/icon'

import '../LandingPageContentSection/LandingPageContentSection.scss'

class LandingPageFlipBoxes extends React.Component {
  render() {
    const { section } = this.props

    return (
      <div className="landing-page-flip-boxes-wrapper section is-medium">
        <div className={`landing-page-flip-boxes ${section.class_name}`}>
          <h3 className="section-header">{section.title}</h3>
          <p
            className="title-lead orange-underline"
            dangerouslySetInnerHTML={{
              __html: section.subtitle,
            }}
          />
          <div className="columns is-multiline">
            {section.flip_boxes_content.map(column => (
              <div className="column is-4" key={v4()}>
                <div className="flip-container has-text-centered">
                  <div className="flipper">
                    <div className="front">
                      <div
                        className="icon-wrapper"
                        style={{
                          color: column.icon_colour
                            ? column.icon_colour
                            : '#242424',
                        }}
                      >
                        <DrawIcon name={column.icon_name} />
                      </div>
                      <h5 className="title">{column.title}</h5>
                      <div
                        className="content"
                        dangerouslySetInnerHTML={{
                          __html: column.intro,
                        }}
                      />
                    </div>
                    <div className="back">
                      <div className="back-content">
                        <h5 className="title">{column.subtitle}</h5>
                        <div
                          className="content"
                          dangerouslySetInnerHTML={{
                            __html: column.content,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  }
}

export default LandingPageFlipBoxes
